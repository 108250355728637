var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 2 },"wrapper-col":{ span: 4 }}},[_c('a-form-item',{attrs:{"label":"开始时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['begin_hour', {
            rules: [
              { required: true, message: '请选择开始时间' },
              { validator: _vm.compareToBeginTime } ]
          }]),expression:"['begin_hour', {\n            rules: [\n              { required: true, message: '请选择开始时间' },\n              { validator: compareToBeginTime },\n            ]\n          }]"}],attrs:{"format":"HH:mm","placeholder":"开始时间"}})],1),_c('a-form-item',{attrs:{"label":"结束时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['end_hour', {
            rules: [
              { required: true, message: '请选择结束时间' },
              { validator: _vm.compareToEndHour } ]
          }]),expression:"['end_hour', {\n            rules: [\n              { required: true, message: '请选择结束时间' },\n              { validator: compareToEndHour },\n            ]\n          }]"}],attrs:{"format":"HH:mm","placeholder":"结束时间"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ offset: 1}}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }