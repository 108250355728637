import request from '@/utils/request'

// 列出所有摄像头
export function findCameraOpenTimeForm() {
  return request({
    url: `/camera_open_times/form`,
    method: 'get',
  })
}


export function createCameraOpenTime(data) {
  return request({
    url: `/camera_open_times`,
    method: 'post',
    data
  })
}
