<template>
  <div>
    <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 2 }"
        :wrapper-col="{ span: 4 }"
    >
      <a-form-item label="开始时间">
        <a-time-picker v-decorator="['begin_hour', {
              rules: [
                { required: true, message: '请选择开始时间' },
                { validator: compareToBeginTime },
              ]
            }]" format="HH:mm" placeholder="开始时间"/>
      </a-form-item>
      <a-form-item label="结束时间">
        <a-time-picker v-decorator="['end_hour', {
              rules: [
                { required: true, message: '请选择结束时间' },
                { validator: compareToEndHour },
              ]
            }]" format="HH:mm" placeholder="结束时间"/>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 1}">
        <a-button type="primary" @click="handleSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {createCameraOpenTime, findCameraOpenTimeForm} from "@/api/camera_open_time";
import {formatDateTime, formatHHMMTime} from "@/utils/time";
import {newVoucher} from "@/api/voucher";
export default {
  name: 'CameraOpenTime',
  components: {
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'camera_open_time' }),
      submitting: false,
    }
  },
  created() {
    this.fetchCameraOpenTimeForm()
  },
  computed: {
  },
  methods: {
    handleSubmit(e){
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.end_hour = formatHHMMTime(values.end_hour)
          values.begin_hour = formatHHMMTime(values.begin_hour)
          createCameraOpenTime(values).then(res => {
            this.submitting = false
          })
        }
      })
    },
    fetchCameraOpenTimeForm() {
      findCameraOpenTimeForm().then((res) => {
        if (res.code === 0) {
          if (res.data.begin_hour != '') {
            this.form.setFieldsValue({begin_hour: this.$moment(res.data.begin_hour, 'HH:mm')})
          }
          if (res.data.end_hour != '') {
            this.form.setFieldsValue({end_hour: this.$moment(res.data.end_hour, 'HH:mm')})
          }
        }
      })
    },
    compareToBeginTime(rule, value, callback) {
      const form = this.form
      if (value === undefined) {
        // eslint-disable-next-line standard/no-callback-literal
        this.$message.warning('请选择开始时间')
        return
      }
      let endHour = form.getFieldValue('end_hour')
      if (endHour != undefined && endHour <= value) {
        this.$message.warning('请选择开始时间小于结束日期，请重新选择')
        callback()
      }
      callback()
    },

    compareToEndHour(rule, value, callback) {
      const form = this.form
      if (value === undefined) {
        this.$message.warning('请选择结束时间')
        return
      }
      let beginHour = form.getFieldValue('begin_hour')
      if (beginHour && beginHour >= value) {
        this.$message.warning('请选择结束时间大于开始时间，请重新选择')
        callback()
      } else {
        callback()
      }
    },
  }
}
</script>
